$color-white:#FFFFFF;
$color-main:#10CAD6;
$color-glay:#96A4BC;
$color-gold:#EFBA04;
$color-default:#45556D;
$color-light--glay:#E8E8EE;
$color-light--blue1:#EDF9FC;
$color-light--blue2:#DFEEF2;
$background-default:#E4E6EA;
$background-glay:#F2F5F9;
$background-challenge:#A0DF71;
$background-light:#E5E5E5;
$background-giay:#F2F5F9;
$background-giay2: #BEC7D9;
$color-opacity--10:#0000001A;
$color-red: #FF0001;
$color-red1: #FF0000;
$header-height:48px;
$tabbar-height:56px;
//font family
$font-family--regular:'Oswald';
$font-family--default:'Noto Sans JP';
//font size
$font-size--regular:14px;
$font-size--normal:12px;
//font weight
$font-weight--normal: 400;
$font-weight--bold: 500;
$font-weight--extra-bold: 700;
$z-index---header:999;
$z-index---icon:998;