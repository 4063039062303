@import "../../sass/shared/variable";

.home-header {
  padding-top: 12px;
}
.redeem-card-screen {
  padding-top: $header-height;
  // padding-bottom: $tabbar-height;
  background-color: $background-glay;
  width: 100%;
  position: relative;
  
  .redeem-card-bottom {
    min-height: calc(100vh - 117px - 48px);
    padding: 24px 15px 40px 16px;
    .top {
      text-align: center;
      padding-bottom: 24px;
    }

    .description {
      padding-bottom: 24px;

      font-family: $font-family--default;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      align-items: center;
      text-align: center;

      /* DefaultColor */
      color: #45556D;
    }

    .title-content {
      /* NotoSansJP/B/20 */
      padding:0px 8px 0px 8px;
      font-family: $font-family--default;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      align-items: center;
      text-align: center;
  
      /* DefaultColor */
      color: #45556D;
    }

    .img-right {
      position: absolute;
      margin-top: 4px;
    }

    .img-left {
      position: absolute;
      margin-top: 4px;
      margin-left: -24px;
    }

    
    .box-item {
      padding-bottom: 14px;
      .title-ticket {
        padding-bottom: 10px;;
        font-family: $font-family--default;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        /* DefaultColor */
        color: #45556D;
      }

      .item-title {
        height: 48px;
        background: #45556D;
        border-radius: 8px;

        .item-text-title {
          position: absolute;
          left: 30px;
          padding-top: 13px;
          /* NotoSansJP/M/13 */
          font-family: $font-family--default;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          // display: flex;
          align-items: center;

          /* White */
          color: #FFFFFF;
        }

        .item-event-code {
          position: absolute;
          right: 25px;
          padding-top: 5px;

          /* Oswald/M/22 */
          font-family: $font-family--regular;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          display: flex;
          align-items: center;
          text-align: right;

          /* White */
          color: #FFFFFF;
        }
      }

      .item-card {
        padding-top: 32px;
        position: relative;
        display: flex;
        align-items: center;
        .item-box-card {
          /* Rectangle 29 */
          width: 160px;
          height: 160px;
          margin: auto;
  
          /* White */
          background: #FFFFFF;
          /* Effect */
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          .img-card {
            width: 104px;
            height: 104px;
            margin-top: 28px;
            margin-left: 28px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .item-text {
        /* NotoSansJP/B/16 */
        padding-top: 16px;
        padding-bottom: 24px;
        font-family: $font-family--default;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        align-items: center;
        text-align: center;
        
        /* DefaultColor */
        color: #45556D;
      }

      .item-ticket {
        padding-top: 8px;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 8px;
        .ticket-img {
          padding-left: 8px;
          padding-bottom: 10px;
          img {
            height: 20px;
            width: 40px;
            vertical-align: middle;
          }
        }
        .ticket-text {
          font-family: $font-family--default;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #45556D;
          margin: 0 9px;
          padding-bottom: 10px;
        }
        .ticket-point {
          span {
            font-family: $font-family--regular;
            font-weight: $font-weight--bold;
            font-size: 18px;
            line-height: 1.1;
            color: $color-default;
            position: absolute;
            top: 7px;
            right: 8px;
          }
        }
      }

      .item-ticket-success {
        padding-top: 8px;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border-radius: 6px;
        .ticket-img {
          padding-left: 8px;
          padding-bottom: 10px;
          img {
            height: 20px;
            width: 40px;
            vertical-align: middle;
          }
        }
        .ticket-text {
          font-family: $font-family--default;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #45556D;
          margin: 0 9px;
          padding-bottom: 10px;
        }
        .ticket-point {
          span {
            font-family: $font-family--regular;
            font-weight: $font-weight--bold;
            font-size: 18px;
            line-height: 1.1;
            color: $color-default;
            position: absolute;
            top: 7px;
            right: 8px;
          }
        }
      }
      
      .ticket-deducted {
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 6px;
        // margin-bottom: 8px;
        .ticket-text {
          font-family: $font-family--default;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #FF4E4F;
          margin: 0 8px;
          padding-bottom: 10px;
          position: absolute;
          right: 0px;
          .text {
            padding-right: 10px;
          }
          .point {
            font-family: $font-family--regular;
            font-weight: $font-weight--bold;
            font-size: 18px;
            line-height: 1.1;
            width: 35px;
          }
        }
      }
      .text-red {
        color: #FF4E4F !important;
        float: right;
      }
      .text-green-blue {
        color: #338EE0 !important;
        float: right;
      }
    }
  }
  .gohome-button {
    height: 40px;
    margin: 0px auto;
    margin-bottom: 24px;
    .main-button {
      .title-name {
        font-size: 15px !important;
      }
    }
  }
}
