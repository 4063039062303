@import "../../sass/shared/variable";


.forgot-password {
  background-color: $background-glay !important;
}
.fgpw-body {
    background: $background-glay;
    height: 100vh;
    .home-header {
        position: relative !important;
    }
}
.forgot-password {
  margin: auto;
  background: $background-glay;
  font-family: "Noto Sans JP";
}
.title-forgotpssword {
  width: 100%;
  display: flex;
  position: inherit;
  justify-content: center;
  padding-top: 28px;
  .imgLeft {
    width: 10%;
    max-width: 30px;
    margin-left: 10px;
    float: left;
  }

  h2 {
    text-align: center;
    color: $color-default;
    font-size: 20px;
    font-family: "Noto Sans JP";
    float: left;
    line-height: 38px;
    margin: 0 10px 0 10px;
    font-weight: 700;
  }

  .imgRight {
    width: 10%;
    max-width: 30px;
    margin-right: 10px;
  }
}
.form-forgot {
  margin: 27px 23px 0 24px;
  p {
    font-size: 14px;
    margin-top: 13px;
    margin-bottom: 6px;
    color: $color-default;
    font-weight: 500;
  }
  input {
    width: 100%;
    height: 48px;
    border: 0;
    font-size: 16px;
    border-style: none;
    padding: 12px 10px 12px 10px;
    caret-color: #10CAD6;
    border-radius: 0;
  }
  input::-webkit-input-placeholder {
    color: #BEC7D9;
    opacity: 0.5;
    font-size: 16px;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #10CAD6;
  }
  .error-message {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FF0001;
    margin-top: 7px;
  }
}

.forgotBtn {
  margin: 32px 48px 0 47px;
  .btn {
    position:relative;
    display: block;
    font-size: 16px;
    color: $color-white;
    padding: 10px;
    border-radius: 50px;
    border: none;
    width: 100%;
    font-family: "Noto Sans JP";
    margin: 4px auto;
    height: 48px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    pointer-events: none;
    color: $color-white;
    background-color: $color-main;
  }
}
.content {
  font-size: 14px;
  word-break: keep-all;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  color: $color-default;
  margin-top: 29px;
}
