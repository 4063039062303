@import "../../sass/shared/variable";

.signin {
  background-color: $background-glay !important;
}
.login-mail {
  height: calc(100vh - 48px);
  @media only screen and (max-height: 568px) {
    height: calc(100vh + 48px);
  }
  margin: auto;
  margin-top: 48px;
  background: $background-glay;
  font-family: "Noto Sans JP";
}
.title-login {
  width: 100%;
  display: flex;
  position: inherit;
  justify-content: center;
  padding-top: 28px;
  .imgLeft {
    width: 10%;
    max-width: 30px;
    margin-left: 10px;
    float: left;
  }

  h2 {
    text-align: center;
    color: $color-default;
    font-size: 20px;
    font-family: "Noto Sans JP";
    float: left;
    line-height:38px;
    margin: 0 10px 0 10px;
    font-weight: 700;
  }

  .imgRight {
    width: 10%;
    max-width: 30px;
    margin-right: 10px;
  }
}
.login-first-time {
  padding-top: 36px;
}
.form-login {
  margin: 27px 23px 0px 24px;
  p {
    font-size: 14px;
    margin-top: 13px;
    margin-bottom: 7px;
    color: $color-default;
    font-weight: 500;
  }
  input {
    width: 100%;
    height: 48px;
    border: 0;
    font-size: 16px;
    border-style: none;
    padding: 12px 10px 12px 10px;
    caret-color: #10CAD6;
    border-radius: 0;
  }
  input::-webkit-input-placeholder {
    color: #BEC7D9;
    opacity: 0.5;
    font-size: 16px;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #10CAD6;
  }
  .forgot-password {
    text-align: right;
    margin-top: 23px;
    color: $color-main;
    font-size: 13px;
    font-weight: 500;
    width: 150px;
    margin-right: 0;
    
    img {
      margin-left: 7px;
    }
  }
  .error-message {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FF0001;
    margin-top: 7px;
  }
}

.loginBtn {
  margin:26px 48px 0 47px;
  .btn {
    position:relative;
    display: block;
    font-size: 16px;
    color: $color-white;
    padding: 10px;
    border-radius: 50px;
    border: none;
    width: 100%;
    font-family: "Noto Sans JP";
    font-weight: bold;
    margin: 4px auto;
    height: 48px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    pointer-events: none;
    color: $color-white;
    background-color: $color-main;
  }
}
.resBtn {
  margin-top: 30px ;
}
.footerLogin {
  font-size: 11px;
  word-break: keep-all;
  text-align: center;
  margin: 20px auto;
  font-weight: 400;
  width: 297px;
  span a{
    color: $color-main;

  }
}
