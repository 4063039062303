@import './fonts/fonts.googleapis.css2Roboto.css';
@import './fonts/fonts.googleapis.css2Noto.css';
@import './fonts/fonts.googleapis.css2Oswald.css';

@charset "UTF-8";

@font-face {
  font-family: "TimesLTStd-Italic";
  src: url("/fonts/TimesLTStd-Italic/TimesLTStd-Italic.eot");
  src: url("/fonts/TimesLTStd-Italic/TimesLTStd-Italic.eot") format("embedded-opentype"),
    url("/fonts/TimesLTStd-Italic/TimesLTStd-Italic.ttf") format("truetype"),
    url("/fonts/TimesLTStd-Italic/TimesLTStd-Italic.woff") format("woff"),
    url("/fonts/TimesLTStd-Italic/TimesLTStd-Italic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'NotoSansJP-Regular';
//   src:  url('/fonts/NotoSansJP-Regular/NotoSansJP-Regular-Alphabetic.eot');
//   src:  url('/fonts/NotoSansJP-Regular/NotoSansJP-Regular-Alphabetic.eot') format('embedded-opentype'),
//     url('/fonts/NotoSansJP-Regular/NotoSansJP-Regular-Alphabetic.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSansJP-Light';
//   src:  url('/fonts/NotoSansJP-Light/NotoSansJP-Light-Alphabetic.eot');
//   src:  url('/fonts/NotoSansJP-Light/NotoSansJP-Light-Alphabetic.eot') format('embedded-opentype'),
//     url('/fonts/NotoSansJP-Light/NotoSansJP-Light-Alphabetic.ttf') format('truetype'),
//     url('/fonts/NotoSansJP-Light/NotoSansJP-Light-Alphabetic.woff') format('woff'),
//     url('/fonts/NotoSansJP-Light/NotoSansJP-Light-Alphabetic.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSansJP-Medium';
//   src:  url('/fonts/NotoSansJP-Medium/NotoSansJP-Medium-Alphabetic.otf');
//   // src:  url('/fonts/NotoSansJP-Medium/NotoSansJP-Medium-Alphabetic.eot') format('embedded-opentype'),
//   //   url('/fonts/NotoSansJP-Medium/NotoSansJP-Medium-Alphabetic.ttf') format('truetype'),
//   //   url('/fonts/NotoSansJP-Medium/NotoSansJP-Medium-Alphabetic.woff') format('woff'),
//   //   url('/fonts/NotoSansJP-Medium/NotoSansJP-Medium-Alphabetic.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSansJP-Bold';
//   src:  url('/fonts/NotoSansJP-Bold/NotoSansJP-Bold-Alphabetic.eot');
//   src:  url('/fonts/NotoSansJP-Bold/NotoSansJP-Bold-Alphabetic.eot') format('embedded-opentype'),
//     url('/fonts/NotoSansJP-Bold/NotoSansJP-Bold-Alphabetic.ttf') format('truetype'),
//     url('/fonts/NotoSansJP-Bold/NotoSansJP-Bold-Alphabetic.woff') format('woff'),
//     url('/fonts/NotoSansJP-Bold/NotoSansJP-Bold-Alphabetic.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP" !important;
  font-weight: 400;
  background-color: #ffffff;
  font-size: 14px;
  margin: 0px;
}

a,
a:hover,
a:active {
  text-decoration: none;
  color: #4a4a4a;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input[type="text"][disabled],
input:disabled {
  cursor: not-allowed;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.error {
  display: contents;
  width: 100%;
  font-size: 12px;
  color: #ff0000;
  line-height: 18px;
}

.msgEmptyData {
  text-align: center;
  margin: 45px 0px;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 500;
  letter-spacing: 1px;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
  color: #ffffff;
  // background-color: #1da1f2;
}
