@import "app.scss";
@import "style.css";
@import './shared/variable';


:root {
  --background-main-color: $color-white;
  --color-main: #4a4a4a;
  --font-family-times: "TimesLTStd-Italic";
  --font-family-sans: "NotoSansJP-Regular";
  --font-family-sans-light: "NotoSansJP-Light";
  --font-family-sans-medium: "NotoSansJP-Medium";
  --font-family-sans-bold: "NotoSansJP-Bold";
}

.clearfix-15 {
  height: 15px;
}

.text-center {
  text-align: center;
}

#main_body {
  .back_drop {
    z-index: 999999999;
  }
}

/* style header layout */

.dialog_common {
  .head_modal {
    min-height: 48px;
    background-color: $color-white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn_close_dialog {
      .icon_close_dialog {
        font-size: 23px;
        color: #979797;
      }
    }

    .head_title_dialog {
      flex: 1;
      font-size: 18px;
      font-weight: 400;
      color: #4a4a4a;
      padding-right: 35px;

      &.title_center {
        text-align: center;
      }
    }
  }
}

 //new
 .icon-icon_menu1:before{
  position: relative;
  top: 2px;
  font-size: 16px;
}
.tool_bar_header {
  min-height: 48px !important;
}

.app_bar_fix_header {
  background-color: $color-white !important;
  color: #4a4a4a !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .header_page {
    text-align: center;
    min-height: 48px;

    .title_header {
      margin: auto;
      font-size: 18px;
      color: #4a4a4a;
      font-weight: 400;
    }

    .btn_goback_header {
      padding: 0px;
      color: #247ae6;
      font-size: 18px;
      margin-left: -5px;
      position: absolute;
      left: 15px;
      top: 9px;

      .icon {
        margin-right: 10px;
        font-size: 30px;
        color: #848484;
      }
    }
  }
}


.container_page {
  // padding-bottom: 60px;
}
