@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xqmc5h');
  src:  url('fonts/icomoon.eot?xqmc5h#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xqmc5h') format('truetype'),
    url('fonts/icomoon.woff?xqmc5h') format('woff'),
    url('fonts/icomoon.svg?xqmc5h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blue_rice:before {
  content: "\e900";
  color: #338ee0;
}
.icon-camera:before {
  content: "\e901";
  color: #36ba9f;
}
.icon-free:before {
  content: "\e902";
  color: #f39;
}
.icon-green_rice:before {
  content: "\e903";
  color: #71c400;
}
.icon-like_button:before {
  content: "\e904";
  color: #7d75ff;
}
.icon-orange_rice:before {
  content: "\e905";
  color: #ff9603;
}
.icon-red_rice:before {
  content: "\e906";
  color: #ff4e4f;
}
.icon-treasure_blow .path1:before {
  content: "\e907";
  color: rgb(175, 99, 30);
}
.icon-treasure_blow .path2:before {
  content: "\e908";
  margin-left: -1.275390625em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_blow .path3:before {
  content: "\e909";
  margin-left: -1.275390625em;
  color: rgb(183, 170, 166);
}
.icon-treasure_blow .path4:before {
  content: "\e90a";
  margin-left: -1.275390625em;
  color: rgb(133, 72, 0);
}
.icon-treasure_blow .path5:before {
  content: "\e90b";
  margin-left: -1.275390625em;
  color: rgb(133, 72, 0);
}
.icon-treasure_blow .path6:before {
  content: "\e90c";
  margin-left: -1.275390625em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blow .path7:before {
  content: "\e90d";
  margin-left: -1.275390625em;
  color: rgb(207, 198, 195);
}
.icon-treasure_blow .path8:before {
  content: "\e90e";
  margin-left: -1.275390625em;
  color: rgb(95, 66, 57);
}
.icon-treasure_blow .path9:before {
  content: "\e90f";
  margin-left: -1.275390625em;
  color: rgb(150, 131, 125);
}
.icon-treasure_blow .path10:before {
  content: "\e910";
  margin-left: -1.275390625em;
  color: rgb(189, 177, 173);
}
.icon-treasure_blow_open .path1:before {
  content: "\e911";
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path2:before {
  content: "\e912";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path3:before {
  content: "\e913";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path4:before {
  content: "\e914";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path5:before {
  content: "\e915";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path6:before {
  content: "\e916";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path7:before {
  content: "\e917";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blow_open .path8:before {
  content: "\e918";
  margin-left: -1.0791015625em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_blow_open .path9:before {
  content: "\e919";
  margin-left: -1.0791015625em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blow_open .path10:before {
  content: "\e91a";
  margin-left: -1.0791015625em;
  color: rgb(255, 191, 0);
}
.icon-treasure_blow_open .path11:before {
  content: "\e91b";
  margin-left: -1.0791015625em;
  color: rgb(207, 198, 195);
}
.icon-treasure_blow_open .path12:before {
  content: "\e91c";
  margin-left: -1.0791015625em;
  color: rgb(139, 92, 41);
}
.icon-treasure_blow_open .path13:before {
  content: "\e91d";
  margin-left: -1.0791015625em;
  color: rgb(91, 60, 0);
}
.icon-treasure_blow_open .path14:before {
  content: "\e91e";
  margin-left: -1.0791015625em;
  color: rgb(183, 170, 166);
}
.icon-treasure_blow_open .path15:before {
  content: "\e91f";
  margin-left: -1.0791015625em;
  color: rgb(133, 72, 0);
}
.icon-treasure_blow_open .path16:before {
  content: "\e920";
  margin-left: -1.0791015625em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blow_open .path17:before {
  content: "\e921";
  margin-left: -1.0791015625em;
  color: rgb(207, 198, 195);
}
.icon-treasure_blow_open .path18:before {
  content: "\e922";
  margin-left: -1.0791015625em;
  color: rgb(183, 170, 166);
}
.icon-treasure_blow_open .path19:before {
  content: "\e923";
  margin-left: -1.0791015625em;
  color: rgb(133, 72, 0);
}
.icon-treasure_blow_open .path20:before {
  content: "\e924";
  margin-left: -1.0791015625em;
  color: rgb(220, 214, 212);
}
.icon-treasure_blow_open .path21:before {
  content: "\e925";
  margin-left: -1.0791015625em;
  color: rgb(139, 92, 41);
}
.icon-treasure_blow_open .path22:before {
  content: "\e926";
  margin-left: -1.0791015625em;
  color: rgb(91, 60, 0);
}
.icon-treasure_blow_open .path23:before {
  content: "\e927";
  margin-left: -1.0791015625em;
  color: rgb(95, 66, 57);
}
.icon-treasure_blue .path1:before {
  content: "\e928";
  color: rgb(12, 106, 185);
}
.icon-treasure_blue .path2:before {
  content: "\e929";
  margin-left: -1.275390625em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_blue .path3:before {
  content: "\e92a";
  margin-left: -1.275390625em;
  color: rgb(173, 209, 255);
}
.icon-treasure_blue .path4:before {
  content: "\e92b";
  margin-left: -1.275390625em;
  color: rgb(0, 87, 148);
}
.icon-treasure_blue .path5:before {
  content: "\e92c";
  margin-left: -1.275390625em;
  color: rgb(0, 87, 148);
}
.icon-treasure_blue .path6:before {
  content: "\e92d";
  margin-left: -1.275390625em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue .path7:before {
  content: "\e92e";
  margin-left: -1.275390625em;
  color: rgb(205, 227, 255);
}
.icon-treasure_blue .path8:before {
  content: "\e92f";
  margin-left: -1.275390625em;
  color: rgb(44, 77, 136);
}
.icon-treasure_blue .path9:before {
  content: "\e930";
  margin-left: -1.275390625em;
  color: rgb(120, 171, 227);
}
.icon-treasure_blue .path10:before {
  content: "\e931";
  margin-left: -1.275390625em;
  color: rgb(173, 209, 255);
}
.icon-treasure_blue_open .path1:before {
  content: "\e932";
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path2:before {
  content: "\e933";
  margin-left: -1.0791015625em;
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path3:before {
  content: "\e934";
  margin-left: -1.0791015625em;
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path4:before {
  content: "\e935";
  margin-left: -1.0791015625em;
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path5:before {
  content: "\e936";
  margin-left: -1.0791015625em;
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path6:before {
  content: "\e937";
  margin-left: -1.0791015625em;
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path7:before {
  content: "\e938";
  margin-left: -1.0791015625em;
  color: rgb(255, 210, 0);
}
.icon-treasure_blue_open .path8:before {
  content: "\e939";
  margin-left: -1.0791015625em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_blue_open .path9:before {
  content: "\e93a";
  margin-left: -1.0791015625em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_open .path10:before {
  content: "\e93b";
  margin-left: -1.0791015625em;
  color: rgb(255, 191, 0);
}
.icon-treasure_blue_open .path11:before {
  content: "\e93c";
  margin-left: -1.0791015625em;
  color: rgb(205, 227, 255);
}
.icon-treasure_blue_open .path12:before {
  content: "\e93d";
  margin-left: -1.0791015625em;
  color: rgb(63, 115, 160);
}
.icon-treasure_blue_open .path13:before {
  content: "\e93e";
  margin-left: -1.0791015625em;
  color: rgb(173, 209, 255);
}
.icon-treasure_blue_open .path14:before {
  content: "\e93f";
  margin-left: -1.0791015625em;
  color: rgb(0, 87, 148);
}
.icon-treasure_blue_open .path15:before {
  content: "\e940";
  margin-left: -1.0791015625em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_open .path16:before {
  content: "\e941";
  margin-left: -1.0791015625em;
  color: rgb(205, 227, 255);
}
.icon-treasure_blue_open .path17:before {
  content: "\e942";
  margin-left: -1.0791015625em;
  color: rgb(173, 209, 255);
}
.icon-treasure_blue_open .path18:before {
  content: "\e943";
  margin-left: -1.0791015625em;
  color: rgb(0, 87, 148);
}
.icon-treasure_blue_open .path19:before {
  content: "\e944";
  margin-left: -1.0791015625em;
  color: rgb(219, 235, 255);
}
.icon-treasure_blue_open .path20:before {
  content: "\e945";
  margin-left: -1.0791015625em;
  color: rgb(63, 115, 160);
}
.icon-treasure_blue_open .path21:before {
  content: "\e946";
  margin-left: -1.0791015625em;
  color: rgb(44, 77, 136);
}
.icon-treasure_blue_open .path22:before {
  content: "\e947";
  margin-left: -1.0791015625em;
  color: rgb(11, 77, 134);
}
.icon-treasure_blue_open .path23:before {
  content: "\e948";
  margin-left: -1.0791015625em;
  color: rgb(11, 77, 134);
}
.icon-treasure_red .path1:before {
  content: "\e949";
  color: rgb(227, 50, 69);
}
.icon-treasure_red .path2:before {
  content: "\e94a";
  margin-left: -1.279296875em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_red .path3:before {
  content: "\e94b";
  margin-left: -1.279296875em;
  color: rgb(233, 171, 58);
}
.icon-treasure_red .path4:before {
  content: "\e94c";
  margin-left: -1.279296875em;
  color: rgb(167, 36, 55);
}
.icon-treasure_red .path5:before {
  content: "\e94d";
  margin-left: -1.279296875em;
  color: rgb(167, 36, 55);
}
.icon-treasure_red .path6:before {
  content: "\e94e";
  margin-left: -1.279296875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red .path7:before {
  content: "\e94f";
  margin-left: -1.279296875em;
  color: rgb(255, 220, 58);
}
.icon-treasure_red .path8:before {
  content: "\e950";
  margin-left: -1.279296875em;
  color: rgb(91, 60, 0);
}
.icon-treasure_red .path9:before {
  content: "\e951";
  margin-left: -1.279296875em;
  color: rgb(208, 130, 58);
}
.icon-treasure_red .path10:before {
  content: "\e952";
  margin-left: -1.279296875em;
  color: rgb(220, 174, 58);
}
.icon-treasure_red_open .path1:before {
  content: "\e953";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_red_open .path2:before {
  content: "\e954";
  margin-left: -1.0791015625em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_open .path3:before {
  content: "\e955";
  margin-left: -1.0791015625em;
  color: rgb(255, 191, 0);
}
.icon-treasure_red_open .path4:before {
  content: "\e956";
  margin-left: -1.0791015625em;
  color: rgb(255, 220, 58);
}
.icon-treasure_red_open .path5:before {
  content: "\e957";
  margin-left: -1.0791015625em;
  color: rgb(145, 65, 57);
}
.icon-treasure_red_open .path6:before {
  content: "\e958";
  margin-left: -1.0791015625em;
  color: rgb(233, 171, 58);
}
.icon-treasure_red_open .path7:before {
  content: "\e959";
  margin-left: -1.0791015625em;
  color: rgb(167, 36, 55);
}
.icon-treasure_red_open .path8:before {
  content: "\e95a";
  margin-left: -1.0791015625em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_open .path9:before {
  content: "\e95b";
  margin-left: -1.0791015625em;
  color: rgb(255, 220, 58);
}
.icon-treasure_red_open .path10:before {
  content: "\e95c";
  margin-left: -1.0791015625em;
  color: rgb(233, 171, 58);
}
.icon-treasure_red_open .path11:before {
  content: "\e95d";
  margin-left: -1.0791015625em;
  color: rgb(167, 36, 55);
}
.icon-treasure_red_open .path12:before {
  content: "\e95e";
  margin-left: -1.0791015625em;
  color: rgb(248, 200, 58);
}
.icon-treasure_red_open .path13:before {
  content: "\e95f";
  margin-left: -1.0791015625em;
  color: rgb(145, 65, 57);
}
.icon-treasure_red_open .path14:before {
  content: "\e960";
  margin-left: -1.0791015625em;
  color: rgb(91, 60, 0);
}
.icon-treasure_red_open .path15:before {
  content: "\e961";
  margin-left: -1.0791015625em;
  color: rgb(135, 48, 39);
}
.icon-treasure_red_open .path16:before {
  content: "\e962";
  margin-left: -1.0791015625em;
  color: rgb(135, 48, 39);
}
.icon-treasure_red_open .path17:before {
  content: "\e963";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_open .path18:before {
  content: "\e964";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_open .path19:before {
  content: "\e965";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_open .path20:before {
  content: "\e966";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_open .path21:before {
  content: "\e967";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_open .path22:before {
  content: "\e968";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_open .path23:before {
  content: "\e969";
  margin-left: -1.0791015625em;
  color: rgb(255, 230, 0);
}
.icon-new_icon .path1:before {
  content: "\e96a";
  color: rgb(255, 51, 153);
}
.icon-new_icon .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-new_icon .path3:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-new_icon .path4:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-badge_s .path1:before {
  content: "\e96e";
  color: rgb(255, 51, 153);
}
.icon-badge_s .path2:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon_point .path1:before {
  content: "\e970";
  color: rgb(16, 202, 214);
}
.icon-icon_point .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(0, 159, 184);
}
.icon-icon_point .path3:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(0, 159, 184);
}
.icon-map_pin .path1:before {
  content: "\e973";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-map_pin .path2:before {
  content: "\e974";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-map_pin .path3:before {
  content: "\e975";
  margin-left: -0.814453125em;
  color: rgb(0, 0, 0);
}
.icon-icon_genre_japanesefood:before {
  content: "\e976";
}
.icon-grobalnavi_mypage:before {
  content: "\e977";
}
.icon-icon_bingo:before {
  content: "\e978";
}
.icon-icon_caution:before {
  content: "\e979";
}
.icon-grobalnavi_home:before {
  content: "\e97a";
}
.icon-icon_checkin:before {
  content: "\e97b";
}
.icon-icon_checkinfix .path1:before {
  content: "\e97c";
  color: rgb(255, 255, 255);
}
.icon-icon_checkinfix .path2:before {
  content: "\e97d";
  margin-left: -1.158203125em;
  color: rgb(16, 202, 214);
}
.icon-icon_close:before {
  content: "\e97e";
}
.icon-icon_currentlocation:before {
  content: "\e97f";
}
.icon-button_mapchange:before {
  content: "\e980";
}
.icon-button_share:before {
  content: "\e981";
}
.icon-button_back:before {
  content: "\e982";
}
.icon-icon_detail:before {
  content: "\e983";
}
.icon-icon_distance:before {
  content: "\e984";
}
.icon-illust_point .path1:before {
  content: "\e985";
  color: rgb(222, 233, 244);
}
.icon-illust_point .path2:before {
  content: "\e986";
  margin-left: -1em;
  color: none;
}
.icon-illust_point .path3:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-illust_point .path4:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(16, 202, 214);
}
.icon-illust_point .path5:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(0, 159, 184);
}
.icon-illust_point .path6:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(0, 159, 184);
}
.icon-illust_point .path7:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_point .path8:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-illust_point .path9:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_point .path10:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon_menu:before {
  content: "\e98f";
}
.icon-illust_panel .path1:before {
  content: "\e990";
  color: rgb(217, 240, 187);
}
.icon-illust_panel .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(223, 242, 197);
}
.icon-illust_panel .path3:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(203, 230, 230);
}
.icon-illust_panel .path4:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(237, 246, 246);
}
.icon-illust_panel .path5:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(222, 239, 239);
}
.icon-illust_panel .path6:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(255, 255, 254);
}
.icon-illust_panel .path7:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(113, 196, 0);
}
.icon-illust_panel .path8:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(113, 196, 0);
}
.icon-illust_panel .path9:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(113, 196, 0);
}
.icon-illust_panel .path10:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(113, 196, 0);
}
.icon-illust_panel .path11:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(113, 196, 0);
}
.icon-illust_panel .path12:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(232, 246, 214);
}
.icon-illust_panel .path13:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_panel .path14:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon_twitter:before {
  content: "\e99e";
  color: #1da1f2;
}
.icon-icon_walktime:before {
  content: "\e99f";
}
.icon-icon_genre_washoku:before {
  content: "\e9a0";
}
.icon-icon_genre_tyuuka:before {
  content: "\e9a1";
}
.icon-icon_genre_train:before {
  content: "\e9a2";
}
.icon-icon_genre_temple:before {
  content: "\e9a3";
}
.icon-icon_genre_takeout:before {
  content: "\e9a4";
}
.icon-icon_genre_shrine:before {
  content: "\e9a5";
}
.icon-icon_genre_park:before {
  content: "\e9a6";
}
.icon-icon_genre_onsen:before {
  content: "\e9a7";
}
.icon-icon_genre_museum:before {
  content: "\e9a8";
}
.icon-icon_genre_lighthouse:before {
  content: "\e9a9";
}
.icon-icon_genre_landmark:before {
  content: "\e9aa";
}
.icon-icon_genre_jr:before {
  content: "\e9ab";
}
.icon-icon_genre_izakaya:before {
  content: "\e9ac";
}
.icon-icon_genre_italian:before {
  content: "\e9ad";
}
.icon-icon_genre_food:before {
  content: "\e9ae";
}
.icon-icon_genre_castle:before {
  content: "\e9af";
}
.icon-icon_genre_cafe:before {
  content: "\e9b0";
}
.icon-treasure_red_effect .path1:before {
  content: "\e9b1";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_red_effect .path2:before {
  content: "\e9b2";
  margin-left: -1.046875em;
  color: rgb(255, 220, 58);
}
.icon-treasure_red_effect .path3:before {
  content: "\e9b3";
  margin-left: -1.046875em;
  color: rgb(159, 90, 83);
}
.icon-treasure_red_effect .path4:before {
  content: "\e9b4";
  margin-left: -1.046875em;
  color: rgb(233, 171, 58);
}
.icon-treasure_red_effect .path5:before {
  content: "\e9b5";
  margin-left: -1.046875em;
  color: rgb(167, 36, 55);
}
.icon-treasure_red_effect .path6:before {
  content: "\e9b6";
  margin-left: -1.046875em;
  color: rgb(91, 60, 0);
}
.icon-treasure_red_effect .path7:before {
  content: "\e9b7";
  margin-left: -1.046875em;
  color: rgb(147, 69, 61);
}
.icon-treasure_red_effect .path8:before {
  content: "\e9b8";
  margin-left: -1.046875em;
  color: rgb(255, 220, 58);
}
.icon-treasure_red_effect .path9:before {
  content: "\e9b9";
  margin-left: -1.046875em;
  color: rgb(233, 171, 58);
}
.icon-treasure_red_effect .path10:before {
  content: "\e9ba";
  margin-left: -1.046875em;
  color: rgb(167, 36, 55);
}
.icon-treasure_red_effect .path11:before {
  content: "\e9bb";
  margin-left: -1.046875em;
  color: rgb(248, 200, 58);
}
.icon-treasure_red_effect .path12:before {
  content: "\e9bc";
  margin-left: -1.046875em;
  color: rgb(159, 90, 83);
}
.icon-treasure_red_effect .path13:before {
  content: "\e9bd";
  margin-left: -1.046875em;
  color: rgb(147, 69, 61);
}
.icon-treasure_red_effect .path14:before {
  content: "\e9be";
  margin-left: -1.046875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_effect .path15:before {
  content: "\e9bf";
  margin-left: -1.046875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_effect .path16:before {
  content: "\e9c0";
  margin-left: -1.046875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_effect .path17:before {
  content: "\e9c1";
  margin-left: -1.046875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_effect .path18:before {
  content: "\e9c2";
  margin-left: -1.046875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_effect .path19:before {
  content: "\e9c3";
  margin-left: -1.046875em;
  color: rgb(227, 50, 69);
}
.icon-treasure_red_effect .path20:before {
  content: "\e9c4";
  margin-left: -1.046875em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_effect .path21:before {
  content: "\e9c5";
  margin-left: -1.046875em;
  color: rgb(255, 255, 200);
}
.icon-treasure_red_effect .path22:before {
  content: "\e9c6";
  margin-left: -1.046875em;
  color: rgb(255, 243, 134);
}
.icon-treasure_red_effect .path23:before {
  content: "\e9c7";
  margin-left: -1.046875em;
  color: rgb(255, 230, 0);
}
.icon-treasure_red_effect .path24:before {
  content: "\e9c8";
  margin-left: -1.046875em;
  color: rgb(255, 208, 0);
}
.icon-treasure_blue_effect .path1:before {
  content: "\e9c9";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_blue_effect .path2:before {
  content: "\e9ca";
  margin-left: -1.046875em;
  color: rgb(205, 227, 255);
}
.icon-treasure_blue_effect .path3:before {
  content: "\e9cb";
  margin-left: -1.046875em;
  color: rgb(107, 147, 182);
}
.icon-treasure_blue_effect .path4:before {
  content: "\e9cc";
  margin-left: -1.046875em;
  color: rgb(173, 209, 255);
}
.icon-treasure_blue_effect .path5:before {
  content: "\e9cd";
  margin-left: -1.046875em;
  color: rgb(0, 87, 148);
}
.icon-treasure_blue_effect .path6:before {
  content: "\e9ce";
  margin-left: -1.046875em;
  color: rgb(44, 77, 136);
}
.icon-treasure_blue_effect .path7:before {
  content: "\e9cf";
  margin-left: -1.046875em;
  color: rgb(56, 110, 156);
}
.icon-treasure_blue_effect .path8:before {
  content: "\e9d0";
  margin-left: -1.046875em;
  color: rgb(205, 227, 255);
}
.icon-treasure_blue_effect .path9:before {
  content: "\e9d1";
  margin-left: -1.046875em;
  color: rgb(173, 209, 255);
}
.icon-treasure_blue_effect .path10:before {
  content: "\e9d2";
  margin-left: -1.046875em;
  color: rgb(0, 87, 148);
}
.icon-treasure_blue_effect .path11:before {
  content: "\e9d3";
  margin-left: -1.046875em;
  color: rgb(219, 235, 255);
}
.icon-treasure_blue_effect .path12:before {
  content: "\e9d4";
  margin-left: -1.046875em;
  color: rgb(107, 147, 182);
}
.icon-treasure_blue_effect .path13:before {
  content: "\e9d5";
  margin-left: -1.046875em;
  color: rgb(56, 110, 156);
}
.icon-treasure_blue_effect .path14:before {
  content: "\e9d6";
  margin-left: -1.046875em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_effect .path15:before {
  content: "\e9d7";
  margin-left: -1.046875em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_effect .path16:before {
  content: "\e9d8";
  margin-left: -1.046875em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_effect .path17:before {
  content: "\e9d9";
  margin-left: -1.046875em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_effect .path18:before {
  content: "\e9da";
  margin-left: -1.046875em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_effect .path19:before {
  content: "\e9db";
  margin-left: -1.046875em;
  color: rgb(12, 106, 185);
}
.icon-treasure_blue_effect .path20:before {
  content: "\e9dc";
  margin-left: -1.046875em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blue_effect .path21:before {
  content: "\e9dd";
  margin-left: -1.046875em;
  color: rgb(255, 255, 200);
}
.icon-treasure_blue_effect .path22:before {
  content: "\e9de";
  margin-left: -1.046875em;
  color: rgb(255, 243, 134);
}
.icon-treasure_blue_effect .path23:before {
  content: "\e9df";
  margin-left: -1.046875em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blue_effect .path24:before {
  content: "\e9e0";
  margin-left: -1.046875em;
  color: rgb(255, 208, 0);
}
.icon-treasure_blown_-effect .path1:before {
  content: "\e9e1";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-treasure_blown_-effect .path2:before {
  content: "\e9e2";
  margin-left: -1.046875em;
  color: rgb(207, 198, 195);
}
.icon-treasure_blown_-effect .path3:before {
  content: "\e9e3";
  margin-left: -1.046875em;
  color: rgb(155, 115, 71);
}
.icon-treasure_blown_-effect .path4:before {
  content: "\e9e4";
  margin-left: -1.046875em;
  color: rgb(183, 170, 166);
}
.icon-treasure_blown_-effect .path5:before {
  content: "\e9e5";
  margin-left: -1.046875em;
  color: rgb(133, 72, 0);
}
.icon-treasure_blown_-effect .path6:before {
  content: "\e9e6";
  margin-left: -1.046875em;
  color: rgb(95, 66, 57);
}
.icon-treasure_blown_-effect .path7:before {
  content: "\e9e7";
  margin-left: -1.046875em;
  color: rgb(138, 91, 40);
}
.icon-treasure_blown_-effect .path8:before {
  content: "\e9e8";
  margin-left: -1.046875em;
  color: rgb(207, 198, 195);
}
.icon-treasure_blown_-effect .path9:before {
  content: "\e9e9";
  margin-left: -1.046875em;
  color: rgb(183, 170, 166);
}
.icon-treasure_blown_-effect .path10:before {
  content: "\e9ea";
  margin-left: -1.046875em;
  color: rgb(133, 72, 0);
}
.icon-treasure_blown_-effect .path11:before {
  content: "\e9eb";
  margin-left: -1.046875em;
  color: rgb(220, 214, 212);
}
.icon-treasure_blown_-effect .path12:before {
  content: "\e9ec";
  margin-left: -1.046875em;
  color: rgb(155, 115, 71);
}
.icon-treasure_blown_-effect .path13:before {
  content: "\e9ed";
  margin-left: -1.046875em;
  color: rgb(138, 91, 40);
}
.icon-treasure_blown_-effect .path14:before {
  content: "\e9ee";
  margin-left: -1.046875em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blown_-effect .path15:before {
  content: "\e9ef";
  margin-left: -1.046875em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blown_-effect .path16:before {
  content: "\e9f0";
  margin-left: -1.046875em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blown_-effect .path17:before {
  content: "\e9f1";
  margin-left: -1.046875em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blown_-effect .path18:before {
  content: "\e9f2";
  margin-left: -1.046875em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blown_-effect .path19:before {
  content: "\e9f3";
  margin-left: -1.046875em;
  color: rgb(175, 99, 30);
}
.icon-treasure_blown_-effect .path20:before {
  content: "\e9f4";
  margin-left: -1.046875em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blown_-effect .path21:before {
  content: "\e9f5";
  margin-left: -1.046875em;
  color: rgb(255, 255, 200);
}
.icon-treasure_blown_-effect .path22:before {
  content: "\e9f6";
  margin-left: -1.046875em;
  color: rgb(255, 243, 134);
}
.icon-treasure_blown_-effect .path23:before {
  content: "\e9f7";
  margin-left: -1.046875em;
  color: rgb(255, 230, 0);
}
.icon-treasure_blown_-effect .path24:before {
  content: "\e9f8";
  margin-left: -1.046875em;
  color: rgb(255, 208, 0);
}
.icon-txticon_play .path1:before {
  content: "\e9f9";
  color: rgb(16, 160, 197);
}
.icon-txticon_play .path2:before {
  content: "\e9fa";
  margin-left: -3.3330078125em;
  color: rgb(16, 202, 214);
}
.icon-txticon_play .path3:before {
  content: "\e9fb";
  margin-left: -3.3330078125em;
  color: rgb(255, 255, 70);
}
.icon-txticon_clear .path1:before {
  content: "\e9fc";
  color: rgb(239, 186, 4);
}
.icon-txticon_clear .path2:before {
  content: "\e9fd";
  margin-left: -4.7138671875em;
  color: rgb(255, 255, 70);
}
.icon-txticon_clear .path3:before {
  content: "\e9fe";
  margin-left: -4.7138671875em;
  color: rgb(255, 115, 104);
}
.icon-stamp_comp_s:before {
  content: "\e9ff";
}
.icon-stamp_comp_b:before {
  content: "\ea00";
  color: #ffdb33;
}
.icon-mappin_select .path1:before {
  content: "\ea01";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-mappin_select .path2:before {
  content: "\ea02";
  margin-left: -0.814453125em;
  color: rgb(0, 0, 0);
}
.icon-icon_point1 .path1:before {
  content: "\ea03";
  color: rgb(16, 202, 214);
}
.icon-icon_point1 .path2:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon_point1 .path3:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(0, 159, 184);
}
.icon-icon_point1 .path4:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon_medal .path1:before {
  content: "\ea07";
  color: rgb(255, 255, 4);
}
.icon-icon_medal .path2:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(255, 230, 4);
}
.icon-icon_medal .path3:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(239, 186, 4);
}
.icon-icon_medal .path4:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(255, 220, 0);
}
.icon-icon_medal .path5:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-icon_jr:before {
  content: "\ea0c";
}
.icon-icon_lock:before {
  content: "\ea0d";
}
.icon-frame_log_panel:before {
  content: "\ea0e";
  color: #deefef;
}
.icon-frame_bingoselect .path1:before {
  content: "\ea0f";
  color: rgb(78, 223, 230);
}
.icon-frame_bingoselect .path2:before {
  content: "\ea10";
  margin-left: -1.154296875em;
  color: rgb(16, 202, 214);
}
.icon-frame_bingolist:before {
  content: "\ea11";
  color: #10cad6;
}
.icon-frame_bingoclear .path1:before {
  content: "\ea12";
  color: rgb(255, 230, 0);
}
.icon-frame_bingoclear .path2:before {
  content: "\ea13";
  margin-left: -1.154296875em;
  color: rgb(255, 219, 51);
}
.icon-frame_bingoclear .path3:before {
  content: "\ea14";
  margin-left: -1.154296875em;
  color: rgb(239, 186, 4);
}
.icon-frame_bingoclear .path4:before {
  content: "\ea15";
  margin-left: -1.154296875em;
  color: rgb(250, 207, 56);
}
.icon-bingoline3 .path1:before {
  content: "\ea16";
  color: rgb(178, 186, 201);
}
.icon-bingoline3 .path2:before {
  content: "\ea17";
  margin-left: -1.138671875em;
  color: rgb(211, 216, 224);
}
.icon-bingoline3 .path3:before {
  content: "\ea18";
  margin-left: -1.138671875em;
  color: rgb(255, 230, 0);
}
.icon-bingoline3 .path4:before {
  content: "\ea19";
  margin-left: -1.138671875em;
  color: rgb(255, 255, 0);
}
.icon-bingoline2 .path1:before {
  content: "\ea1a";
  color: rgb(178, 186, 201);
}
.icon-bingoline2 .path2:before {
  content: "\ea1b";
  margin-left: -1.138671875em;
  color: rgb(211, 216, 224);
}
.icon-bingoline2 .path3:before {
  content: "\ea1c";
  margin-left: -1.138671875em;
  color: rgb(255, 230, 0);
}
.icon-bingoline2 .path4:before {
  content: "\ea1d";
  margin-left: -1.138671875em;
  color: rgb(255, 255, 0);
}
.icon-bingoline1 .path1:before {
  content: "\ea1e";
  color: rgb(178, 186, 201);
}
.icon-bingoline1 .path2:before {
  content: "\ea1f";
  margin-left: -1.138671875em;
  color: rgb(211, 216, 224);
}
.icon-bingoline1 .path3:before {
  content: "\ea20";
  margin-left: -1.138671875em;
  color: rgb(255, 230, 0);
}
.icon-bingoline1 .path4:before {
  content: "\ea21";
  margin-left: -1.138671875em;
  color: rgb(255, 255, 0);
}
.icon-illust_error .path1:before {
  content: "\ea22";
  color: rgb(213, 213, 213);
}
.icon-illust_error .path2:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(196, 196, 196);
}
.icon-illust_error .path3:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-illust_error .path4:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(131, 131, 131);
}
.icon-illust_error .path5:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(69, 69, 69);
}
.icon-frame_panelinfo .path1:before {
  content: "\ea27";
  color: rgb(222, 239, 239);
}
.icon-frame_panelinfo .path2:before {
  content: "\ea28";
  margin-left: -0.8798828125em;
  color: rgb(255, 255, 255);
}
.icon-illust_404 .path1:before {
  content: "\ea29";
  color: rgb(223, 238, 242);
}
.icon-illust_404 .path2:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(195, 223, 231);
}
.icon-illust_404 .path3:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-button_bingoinfo .path1:before {
  content: "\ea2c";
  color: rgb(16, 202, 214);
}
.icon-button_bingoinfo .path2:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-button_bingoinfo .path3:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(16, 202, 214);
}
.icon-hex_red .path1:before {
  content: "\ea2f";
  color: rgb(255, 157, 162);
  opacity: 0.199;
}
.icon-hex_red .path2:before {
  content: "\ea30";
  margin-left: -0.875em;
  color: rgb(245, 25, 25);
  opacity: 0.199;
}
.icon-hex_orange .path1:before {
  content: "\ea31";
  color: rgb(255, 179, 65);
  opacity: 0.199;
}
.icon-hex_orange .path2:before {
  content: "\ea32";
  margin-left: -0.875em;
  color: rgb(234, 108, 0);
  opacity: 0.199;
}
.icon-hex_green .path1:before {
  content: "\ea33";
  color: rgb(195, 255, 134);
  opacity: 0.199;
}
.icon-hex_green .path2:before {
  content: "\ea34";
  margin-left: -0.875em;
  color: rgb(66, 195, 9);
  opacity: 0.199;
}
.icon-hex_blue .path1:before {
  content: "\ea35";
  color: rgb(97, 187, 253);
  opacity: 0.199;
}
.icon-hex_blue .path2:before {
  content: "\ea36";
  margin-left: -0.875em;
  color: rgb(5, 129, 235);
  opacity: 0.199;
}
.icon-checkin_red .path1:before {
  content: "\ea37";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-checkin_red .path2:before {
  content: "\ea38";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkin_red .path3:before {
  content: "\ea39";
  margin-left: -0.814453125em;
  color: rgb(255, 78, 79);
}
.icon-checkin_red .path4:before {
  content: "\ea3a";
  margin-left: -0.814453125em;
  color: rgb(255, 78, 79);
}
.icon-checkin_red .path5:before {
  content: "\ea3b";
  margin-left: -0.814453125em;
  color: rgb(255, 78, 79);
}
.icon-checkin_red .path6:before {
  content: "\ea3c";
  margin-left: -0.814453125em;
  color: rgb(255, 78, 79);
}
.icon-checkin_red .path7:before {
  content: "\ea3d";
  margin-left: -0.814453125em;
  color: rgb(255, 78, 79);
}
.icon-checkin_red .path8:before {
  content: "\ea3e";
  margin-left: -0.814453125em;
  color: rgb(255, 78, 79);
}
.icon-checkin_orange .path1:before {
  content: "\ea3f";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-checkin_orange .path2:before {
  content: "\ea40";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkin_orange .path3:before {
  content: "\ea41";
  margin-left: -0.814453125em;
  color: rgb(255, 150, 3);
}
.icon-checkin_orange .path4:before {
  content: "\ea42";
  margin-left: -0.814453125em;
  color: rgb(255, 150, 3);
}
.icon-checkin_orange .path5:before {
  content: "\ea43";
  margin-left: -0.814453125em;
  color: rgb(255, 150, 3);
}
.icon-checkin_orange .path6:before {
  content: "\ea44";
  margin-left: -0.814453125em;
  color: rgb(255, 150, 3);
}
.icon-checkin_orange .path7:before {
  content: "\ea45";
  margin-left: -0.814453125em;
  color: rgb(255, 150, 3);
}
.icon-checkin_orange .path8:before {
  content: "\ea46";
  margin-left: -0.814453125em;
  color: rgb(255, 150, 3);
}
.icon-checkin_green .path1:before {
  content: "\ea47";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-checkin_green .path2:before {
  content: "\ea48";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkin_green .path3:before {
  content: "\ea49";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkin_green .path4:before {
  content: "\ea4a";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkin_green .path5:before {
  content: "\ea4b";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkin_green .path6:before {
  content: "\ea4c";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkin_green .path7:before {
  content: "\ea4d";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkin_green .path8:before {
  content: "\ea4e";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkin_blue .path1:before {
  content: "\ea4f";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-checkin_blue .path2:before {
  content: "\ea50";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkin_blue .path3:before {
  content: "\ea51";
  margin-left: -0.814453125em;
  color: rgb(51, 142, 224);
}
.icon-checkin_blue .path4:before {
  content: "\ea52";
  margin-left: -0.814453125em;
  color: rgb(51, 142, 224);
}
.icon-checkin_blue .path5:before {
  content: "\ea53";
  margin-left: -0.814453125em;
  color: rgb(51, 142, 224);
}
.icon-checkin_blue .path6:before {
  content: "\ea54";
  margin-left: -0.814453125em;
  color: rgb(51, 142, 224);
}
.icon-checkin_blue .path7:before {
  content: "\ea55";
  margin-left: -0.814453125em;
  color: rgb(51, 142, 224);
}
.icon-checkin_blue .path8:before {
  content: "\ea56";
  margin-left: -0.814453125em;
  color: rgb(51, 142, 224);
}
.icon-checkinselect_green .path1:before {
  content: "\ea57";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-checkinselect_green .path2:before {
  content: "\ea58";
  margin-left: -0.814453125em;
  color: rgb(113, 196, 0);
}
.icon-checkinselect_green .path3:before {
  content: "\ea59";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkinselect_green .path4:before {
  content: "\ea5a";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkinselect_green .path5:before {
  content: "\ea5b";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkinselect_green .path6:before {
  content: "\ea5c";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-checkinselect_green .path7:before {
  content: "\ea5d";
  margin-left: -0.814453125em;
  color: rgb(255, 255, 255);
}
.icon-icon_menu1:before {
  content: "\ea5e";
  color: #8592aa;
}
.icon-icon_home:before {
  content: "\ea5f";
  color: #8592aa;
}
.icon-icon_bingomap:before {
  content: "\ea60";
  color: #8592aa;
}
.icon-illust_panel_red .path1:before {
  content: "\ea61";
  color: rgb(255, 228, 228);
}
.icon-illust_panel_red .path2:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(255, 215, 215);
}
.icon-illust_panel_red .path3:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.15;
}
.icon-illust_panel_red .path4:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_panel_red .path5:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-illust_panel_red .path6:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(203, 230, 230);
}
.icon-illust_panel_red .path7:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(237, 246, 246);
}
.icon-illust_panel_red .path8:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(178, 218, 218);
}
.icon-illust_panel_red .path9:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(222, 239, 239);
}
.icon-illust_panel_red .path10:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(255, 255, 254);
}
.icon-illust_panel_orange .path1:before {
  content: "\ea6b";
  color: rgb(255, 233, 190);
}
.icon-illust_panel_orange .path2:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(255, 222, 160);
}
.icon-illust_panel_orange .path3:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.15;
}
.icon-illust_panel_orange .path4:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_panel_orange .path5:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-illust_panel_orange .path6:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(203, 230, 230);
}
.icon-illust_panel_orange .path7:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(237, 246, 246);
}
.icon-illust_panel_orange .path8:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(178, 218, 218);
}
.icon-illust_panel_orange .path9:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(222, 239, 239);
}
.icon-illust_panel_orange .path10:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(255, 255, 254);
}
.icon-illust_panel_green .path1:before {
  content: "\ea75";
  color: rgb(225, 246, 214);
}
.icon-illust_panel_green .path2:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(210, 240, 187);
}
.icon-illust_panel_green .path3:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.15;
}
.icon-illust_panel_green .path4:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_panel_green .path5:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-illust_panel_green .path6:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(203, 230, 230);
}
.icon-illust_panel_green .path7:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(237, 246, 246);
}
.icon-illust_panel_green .path8:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(178, 218, 218);
}
.icon-illust_panel_green .path9:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(222, 239, 239);
}
.icon-illust_panel_green .path10:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(255, 255, 254);
}
.icon-illust_panel_blue .path1:before {
  content: "\ea7f";
  color: rgb(204, 232, 249);
}
.icon-illust_panel_blue .path2:before {
  content: "\ea80";
  margin-left: -1em;
  color: rgb(185, 225, 247);
}
.icon-illust_panel_blue .path3:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.15;
}
.icon-illust_panel_blue .path4:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.icon-illust_panel_blue .path5:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-illust_panel_blue .path6:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(203, 230, 230);
}
.icon-illust_panel_blue .path7:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(237, 246, 246);
}
.icon-illust_panel_blue .path8:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(178, 218, 218);
}
.icon-illust_panel_blue .path9:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(222, 239, 239);
}
.icon-illust_panel_blue .path10:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(255, 255, 254);
}
.icon-bingo-map .path1:before {
  content: "\ea89";
  color: rgb(255, 51, 153);
}
.icon-bingo-map .path2:before {
  content: "\ea8a";
  margin-left: -1.23046875em;
  color: rgb(133, 146, 170);
}
.icon-bingo-map .path3:before {
  content: "\ea8b";
  margin-left: -1.23046875em;
  color: rgb(133, 146, 170);
}
.icon-btnJumToTop .path1:before {
  content: "\ea8c";
  color: rgb(16, 202, 214);
}
.icon-btnJumToTop .path2:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Free:before {
  content: "\ea8e";
  color: #f39;
}
